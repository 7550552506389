<template>
  <v-container class="py-0">
    <v-row>
      Movement layer
    </v-row>

    <v-row>
      <v-checkbox
        v-model="showMovementLayer"
        :label="movementLayerToggleText"
        dense
        class="py-0 my-0 pt-1"
      />
    </v-row>

    <v-row>
      Stay time layer
    </v-row>

    <v-row>
      <v-checkbox
        v-model="showStayLayer"
        :label="stayLayerToggleText"
        dense
        class="py-0 my-0 pt-1"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showMovementLayer: true,
      showStayLayer: true,
    }
  },

  computed: {
    movementLayerToggleText() {
      return this.showMovementLayer ? 'On' : 'Off'
    },

    stayLayerToggleText() {
      return this.showStayLayer ? 'On' : 'Off'
    },
  },

  watch: {
    showMovementLayer() {
      this.layerVisibilityFilterChange()
    },

    showStayLayer() {
      this.layerVisibilityFilterChange()
    },
  },

  methods: {
    layerVisibilityFilterChange() {
      this.$emit('layerVisibilityFilterChanged', this.showMovementLayer, this.showStayLayer)
    },
  },
}
</script>
