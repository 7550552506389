<template>
  <div>
    <v-overlay :value="$apollo.queries.meerData.loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <LeafletMap
      :map-data="mapData"
      :movement-data="movementData"
      :showMovementLayer="showMovementLayer"
      :showStayLayer="showStayLayer"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'

import LeafletMap from '../heatmaps-shared/LeafletMap.vue'

export default {
  props: {
    showMovementLayer: {
      typeof: Boolean,
      required: true,
      dafault: true,
    },

    showStayLayer: {
      typeof: Boolean,
      required: true,
      default: true,
    },

    selectedTimeRange: {
      typeof: String,
      default: '',
    },

    selectedFrom: {
      typeof: String,
      default: '',
    },

    selectedUntil: {
      typeof: String,
      default: '',
    },
  },

  components: {
    LeafletMap,
  },

  apollo: {
    meerData: {
      query: gql`
        query ($timeRange: AnalyticsTimeRange!, $from: DateTimeTz, $until: DateTimeTz) {
          meerData(timeRange: $timeRange, from: $from, until: $until) {
            timeRange
            geohashCells {
              geohash
              speed_kmph
              azimuth_deg
              stay_h
              centroid {
                lat
                lng
              }
              destination_point {
                lat
                lng
              }
            }
          }
        }
      `,
      variables() {
        return {
          timeRange: this.selectedTimeRange,
          from: this.selectedFrom,
          until: this.selectedUntil,
        }
      },
    },
  },

  computed: {
    mapData() {
      const array = []

      if (this.meerData) {
        this.meerData.geohashCells.forEach(cell => {
          array.push({
            lat: cell.centroid.lat,
            lng: cell.centroid.lng,
            count: cell.stay_h,
          })
        })
      }

      return array
    },

    movementData() {
      const array = []

      if (this.meerData) {
        this.meerData.geohashCells.forEach(cell => {
          array.push({
            startingPoint: {
              lat: cell.centroid.lat,
              lon: cell.centroid.lng,
            },

            destinationPoint: {
              lat: cell.destination_point.lat,
              lon: cell.destination_point.lng,
            },

            speed: cell.speed_kmph,
          })
        })
      }

      return array
    },
  },
}
</script>
