<template>
  <div>
    <v-card class="mb-4 px-4 pb-4">
      <TimeFilters
        @timeFilterChanged="changeTimeFilters"
      />
    </v-card>

    <v-card>
      <MeerDataMap
        class="px-2 py-2"
        :show-movement-layer="showMovementLayer"
        :show-stay-layer="showStayLayer"
        :selected-from="selectedFrom"
        :selected-until="selectedUntil"
        :selected-time-range="selectedTimeRange"
      />
    </v-card>

    <div class="d-flex">
      <v-card
        class="mt-n16"
        style="z-index: 2;"
        width="180"
        elevation="3"
        rounded="xl"
      >
        <v-card-title class="ml-2 pb-6 body-2">
          Layer visibility:
        </v-card-title>

        <v-card-text>
          <LayerVisibilityOptions
            class="ml-2"
            @layerVisibilityFilterChanged="layerVisibilityFilterChange"
          />
        </v-card-text>
      </v-card>

      <v-card
        v-if="showMovementLayer"
        class="mt-n16 ml-2"
        style="z-index: 2;"
        width="210"
        elevation="3"
        rounded="xl"
      >
        <v-card-title class="ml-2 pb-3 body-2">
          Movement speed (km/h):
        </v-card-title>

        <v-card-text>
          <MovementSpeedLegend />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import MeerDataMap from '../components/meer/MeerDataMap.vue'
import LayerVisibilityOptions from '../components/meer/LayerVisibilityOptions.vue'
import MovementSpeedLegend from '../components/meer/MovementSpeedLegend.vue'
import TimeFilters from '../components/heatmaps-shared/TimeFilters.vue'

export default {
  components: {
    MeerDataMap,
    LayerVisibilityOptions,
    MovementSpeedLegend,
    TimeFilters,
  },

  data() {
    return {
      showMovementLayer: true,
      showStayLayer: true,
      selectedTimeRange: '',
      selectedFrom: '',
      selectedUntil: '',
    }
  },

  methods: {
    layerVisibilityFilterChange(showMovementLayer, showStayLayer) {
      this.showMovementLayer = showMovementLayer
      this.showStayLayer = showStayLayer
    },

    changeTimeFilters(selectedFrom, selectedUntil, selectedTimeRange) {
      this.selectedFrom = this.$moment(selectedFrom).format()
      this.selectedUntil = this.$moment(selectedUntil).format()
      this.selectedTimeRange = selectedTimeRange
    },
  },
}
</script>
